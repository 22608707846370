"use client";

import axios from "axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import qs from "query-string";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { getEnv } from "@/utils/env/getEnv";
import type { ReactNode } from "react";

const queryClient = new QueryClient();

axios.defaults.baseURL = getEnv("NEXT_PUBLIC_API_ORIGIN");
axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  (config) => {
    return {
      ...config,
      startTime: new Date().getTime(),
    };
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  async (response) => {
    const MINIMUM_GET_DELAY = 300;

    // @ts-ignore
    const latency = new Date().getTime() - response.config.startTime;
    const shouldNotDelay =
      MINIMUM_GET_DELAY < latency || response.config.method !== "get";

    if (shouldNotDelay) {
      return response;
    }

    const remainDelay = MINIMUM_GET_DELAY - latency;
    const [responseWithDelay] = await Promise.all([
      response,
      new Promise((resolve) => setTimeout(resolve, remainDelay)),
    ]);

    return responseWithDelay;
  },
  (error) => Promise.reject(error),
);

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params);
};

export function ReactQueryClientProvider({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      {children}
    </QueryClientProvider>
  );
}
